.about-container
{
    margin:4rem 6 rem;
    text-align: start;
}
.about-container h1{
    padding-bottom: 1rem;
}
.about-container p{
    padding-bottom: 2rem;
}
@media screen and(max-width:850px){
    .about-container
    {
        margin: 4rem 2rem;
    }
}