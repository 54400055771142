.from-container
{
    margin:4rem 6rem;
    color: black;
    text-align: center;
}
.from-container form{
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: auto;
}
.from-container input
{
height: 3rem;
padding: 0 1rem;
margin-bottom: 2rem;
border-radius:.3rem ;
border-color:1px solid #2a2a2a;
}
.from-container textarea
{

padding: 0 1rem;
margin-bottom: 2rem;
border-radius:.3rem ;
border-color:1px solid #2a2a2a;
}
.from-container button{
    display: inline;
    width: 100%;
}
@media screen and(max-width:850px) {
    .from-container
    {
        margin: 4rem 2rem;
    }
    .from-container .from-container{
        padding-top: 2rem;
        width: 90%;
    }
   
}