.NavbarItems{
    background-color: rgb(232, 237, 164);
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: 0,30px;
     box-shadow: 0 5px 15px 0 rgba(175, 168, 168, 0.25);
     border-radius: 13px;
     width: 95%;
     height: 80px;
     position: fixed;
     top: 20px;
     left: 50%;
     transform: translate(-50%);
}
.navbar-logo
{
    color: rgb(0, 0, 0);
    font-size: 2rem;
    cursor: pointer;
}
.nav-menu
{ 
 display: grid;
 grid-template-columns: repeat(6, auto);
 grid-gap: 10px;
 list-style: none;
 align-items: center;
}
.nav-links
{ text-decoration: none;
  color: #222;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0.7rem 1rem;
  white-space: nowrap; 
}
.nav-links i{
    padding-right: 10px;
}
.nav-links:hover{
    background-color: aqua;
    color: aliceblue;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}
.nav-links-mobile
{
   display: none; 
}
.button{
    padding: 0.5rem 1rem;
    white-space: nowrap;
    border-radius: 0.3rem;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition:0.2s ease-in-out ;
    margin-right: 25px;
}
.button:hover
{
    background-color: #0a0404;
    color: aliceblue;
}
.menu-icons
{
display: none;
}

@media screen and (max-width:850px )
{    .NavbarItems{
    z-index: 99;
         }
    .nav-menu
    { 
     display: flex;
     flex-direction: column;
     justify-content: flex-start;
     background-color: rgb(235, 235, 235);
     width: 100%;
     height: auto;
     position: absolute;
     top: 0;
     left: -100%;
     opacity: 0;
     align-items: center;
     padding: 40px 0 30px 0;
     margin: 0;
     z-index: -1;
     transition: 0.3s ease-in-out;
     border-radius: 13px;
    }
   
     .nav-links
    {  display: block;
        width: 100%;
        font-size: 1.2rem;
        padding: 1rem 0;
    }
    .nav-links:hover{
        background-color: rgb(18, 132, 132);
        transition: none;
        display: block;
        width: 100%; 
    }
    button{
        display: none ;
    }
    .nav-links-mobile
    {
   display: block; 
   text-align: center;
   padding: 1.5rem;
   margin: auto;
   border-radius: 4px; 
   width: 100%;
   background-color: rgb(18, 132, 132);
   text-decoration:none ;
   color: aliceblue;
   font-size: 1.2rem;
   font-weight: 700;
   letter-spacing: 2px;
}
.nav-menu.active
{
 left:0;
 opacity: 1;
 z-index: -1;
 transition: 0.3s ease-in-out;
 

} 
.menu-icons
{
display: block;
}
.menu-icons i{
    font-size: 1.2rem;
    color:#222 ;
    margin-right: 1.5rem;
}
}
    
