img{
    width: 100%;
    height: 100%;
   object-fit: cover;
}
.hero
{
    width: 100%;
    height: 100vh;
    /* position: relative; */
}
.hero-mid
{
    width: 100%;
    height: 70vh;
}
.hero-mid h1{
    padding-bottom: 6rem !important;
}
.hero-text{
    position: absolute;
    top: 55%;
    left: 50%;
  transform: translate(-50%,-50%);
    width: 100%;
  text-align: center;

}
.hero-text h1{
    font-size: 3rem;
    font-weight: 800;
    background: rgb(241, 241, 241);
    -webkit-background-clip:text ;
    -webkit-text-fill-color: transparent;
}
.hero-text p{
    font-size: 1.5rem;
    font-weight: 600;
    color:  rgb(241, 241, 241);
  
    padding:0.5rem 0 2rem 0;
}
.hero-text .show{
   text-decoration: none; 
   background-color:   rgb(114, 177, 13);
   padding:1rem 2rem ;
   border-radius: 6px;
   font-size: 1.1rem;
   font-weight: bold;
   letter-spacing: 2px;
   color: black;
}
.hero-text .hide
{
    display: none;
}
@media screen and(max-width:555px)
{
    .hero-text h1
    {
        padding: 10px 10px;
    }
    .hero-text{
        font-size: 1.1rem;
        padding: 0 0 2rem 0;
    }
    .hero-text .show{
      
        padding:.6rem 1.1rem ;
        border-radius: 6px;  
        font-size: 1 rem;
        
     }
}
    
